const ArrowDownIcon = (props: any) => {
  return (
    <svg
      width={props?.width || "16"}
      height={props?.height || "11"}
      viewBox="0 0 20 11"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0.458303 1.525C0.11525 1.19167 0.0951939 0.854333 0.398138 0.513C0.701082 0.171666 1.16975 0.000666667 1.80414 0L18.1916 0C18.825 0 19.2936 0.171 19.5976 0.513C19.9016 0.855 19.8816 1.19233 19.5375 1.525L11.3437 9.675C11.0271 9.975 10.5784 10.125 9.99788 10.125C9.41733 10.125 8.96872 9.975 8.65205 9.675L0.458303 1.525Z"
        fill={props.fill || "#F135A3"}
      />
    </svg>
  );
};

export { ArrowDownIcon };
