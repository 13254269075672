const CheckboxCheckedIcon = (props: any) => {
  return (
    <svg
      width={props.width || "16"}
      height={props.height || "16"}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.6478 0.496216H4.35967C1.62831 0.496216 0 2.12453 0 4.85589V11.1365C0 13.8754 1.62831 15.5037 4.35967 15.5037H10.6403C13.3717 15.5037 15 13.8754 15 11.144V4.85589C15.0075 2.12453 13.3792 0.496216 10.6478 0.496216ZM11.0905 6.27409L6.83591 10.5287C6.73085 10.6338 6.58828 10.6938 6.43821 10.6938C6.28813 10.6938 6.14556 10.6338 6.04051 10.5287L3.91695 8.40516C3.69934 8.18755 3.69934 7.82737 3.91695 7.60976C4.13456 7.39215 4.49474 7.39215 4.71235 7.60976L6.43821 9.33562L10.2951 5.4787C10.5127 5.26109 10.8729 5.26109 11.0905 5.4787C11.3081 5.69631 11.3081 6.04898 11.0905 6.27409Z"
        fill={props.fill || "#F135A3"}
      />
    </svg>
  );
};

export { CheckboxCheckedIcon };
