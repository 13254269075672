export enum EMeasurementUnits {
    CM = 'cm',
    MM = 'mm',
    UM = 'um',
    GRAM = 'gram',
    METER = 'meter',
    KG = 'kg',
    PPM = 'ppm',
    PERCENTAGE = 'percentage',
    MINUTE = 'minute',
    INCH = 'inch',
    MICRO_INCH = 'microInch',
    SQUARE_METER = 'squareMeter',
    SQUARE_CM = 'squareCM',
    SETS_HOUR = 'setsHour',
    SPH = 'sph',
    PPH = 'pph',
    BOOK_PH = 'bookPh',
    SECOND = 'second',
    CHICKS_PM = 'chicksPM',
    CUTS_PH = 'cutsPH',
    CM_SEC = 'cm-sec',
    MM_P_SECOND = 'mmPerSecond',
    STAPLES_P_MIN = 'staplesPerMin',
    MM_P_MIN = 'mmPerMin',
    PIERCING_PER_MIN = 'PiercingPMin',
    METER_P_MIN = 'meterPerMin',
    BENDING_P_H = 'bendingPH',
    BOOKLETS_P_MIN = 'bookletsPMin',
    BOOKS_P_MIN = 'booksPerMinute',
    SETS_MIN = 'setsPerMinute',
    COVERS_P_H = "coversPH",
    PACKAGE_P_H = 'packagePH',
    CUTS_P_MIN = 'cutsPMIN',
    SQUARE_METER_P_MIN = 'squareMeterPerMin',
    SQUARE_METER_P_HOUR = 'squareMeterPerHour',
    SQUARE_CM_P_MIN = 'squareCMPerHour',
    PAPER_SETS_PER_HOUR  = 'paperSetsPerHour',
    METER_PER_HOUR = 'meterPerHour',
    CUPS_PER_HOUR = 'cupsPerHour',
    SQUARE_CM_HOUR = 'squareCMPerH',
    LETTERS_PER_HOUR = 'lettersHour',
    PROFILE_PER_HOUR = 'profileHour',
    SQUARE_FEET_HOUR = 'squareFeetHour',
    INCH_PER_HOUR = 'inchHour',
    SQUARE_FEET_P_MIN = 'squareFeetMinute',
    INCH_P_MIN = 'inchMinute',
    INCH_SEC = 'inchSecond',
    SQUARE_FEET = 'squareFeet',
    LB = 'lb',
    PT = 'pt',

}